import React, {useEffect, useState} from "react";
import Map from "./Map";
import CareerModal from "./shared/modals/CareerModal";
import HorizontalNavMenu from "./shared/HorizontalNav";
import Header from "./shared/Header";
import AboutMe from "./AboutMe";
import Progress from "./Progress";
import Icon from "./shared/Icon";
import {useSelector} from "react-redux";
import { setScreen } from "../redux/screenState";
import {useDispatch} from "react-redux";
import ChatBot from "./chatbot/ChatBot";
import {openChat, closeChat, toggleChat, setLoading, clearHistory} from '../redux/store';
import Chatbot from "./chatbot/bot-icon/Chatbot";  // Adjust the import path as needed
import FloatingNav from "./shared/FloatingNav";
import AchievementsModal from "./shared/modals/AchievementsModal";
import { useNavigate } from "react-router-dom";
import { addJob } from "../util/addJob";
import {fetchGreeting} from "../util/fetchGreeting";
import { fetchMap } from "../util/fetchMap";
import {prepareTabModalData} from "../util/prepareTabModalData";
import { prepareMapNudgeData } from "../util/prepareMapNudgeData";
import { v4 as uuid } from 'uuid';
import QuestModal from "./shared/modals/QuestModal";
import {fetchAboutMe} from "../util/fetchAboutMe";
import { updateRatings } from "../redux/user";
import { fetchPathway } from "../util/fetchPathway";

export default function MainPage (props) {
    const [activeTab, setActiveTab] = useState(props.active ? props.active : 0);
    const [modal, setModal] = useState({open:false, type:''});
    const [questModal, setQuestModal] = useState({open:false, quest:''});
    const [achievementsOpen,setAchievementsOpen] = useState(false);
    const [activeJob, setActiveJob] = useState('');

    //for now
    const [ratings, setRatings] = useState(null);
    const user = useSelector(state => state.user)
    const chatOpen = useSelector(state => state.chat.isOpen);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(user, user.ratings)
        if (user && !ratings) {
            setRatings(user.ratings)
        }
    },[user])

    useEffect(() => {
        setActiveTab(props.active)
        dispatch(setScreen('intro'))
    },[props.active])

    const tabClick = async (val) => {
        if (val.item === 1) {
            // load jobs chat
            let data = {
                id: uuid(),
                title: 'Business management and administration',
                message: 'hi'
            }
            dispatch(setLoading(true))
            dispatch(clearHistory());
            fetchAboutMe(dispatch)
        }
        if (val.item === 0) {
            // load greeting chat
            // fetchGreeting(dispatch);
            fetchMap(dispatch)
        }

        navigate({
            pathname: "/" + val.url
        })
        setActiveTab(val.item);
    }

    const openQuest = (info) => {
        setQuestModal(info)

        //close other modals 
        setAchievementsOpen(false);
        setModal({...modal, open:false})
        
        //update chat prompt
        fetchPathway(dispatch)
    }

    async function closeQuest() {
        setQuestModal({...questModal, open:false})
        await prepareMapNudgeData(dispatch) // prepares the data to send to our chat bot
    }

    const addRating = (cluster, rating) =>{
        //check if cluster is
        console.log(ratings)
        let currentRatings = [...ratings],
            clusterIndex = currentRatings.findIndex(f => f.cluster === cluster);

            if (clusterIndex) {
                let updatedRating = {
                    ...currentRatings[clusterIndex],
                    rating: rating
                }
                currentRatings[clusterIndex] = updatedRating
            }
            
            // console.log(currentRatings[clusterIndex].rating)
            setRatings(currentRatings)
            dispatch(updateRatings(currentRatings))
    }
    return (
        <div className={chatOpen ? "page-wrapper chat-open" : "page-wrapper"}>
            {/* <HorizontalNavMenu active={activeTab}/> */}
            <Header mode="dark" logo={true} auth={false}/>
            <main>
                <div className="content-container">
                    <FloatingNav active={activeTab} 
                        onClick={tabClick} 
                        achievementsOpen={achievementsOpen}
                        achievements={() => {setAchievementsOpen(true);setModal({...modal, open:false});setQuestModal({...questModal, open:false})}} 
                        openQuest={(info) => {openQuest(info)}}
                        quests={user ? user.jobs : []}
                        avatar={activeJob}
                        />
                    {achievementsOpen && <AchievementsModal close={() => setAchievementsOpen(false)} 
                        openQuest={(info) => {openQuest(info)}}
                        quests={user ? user.jobs : []}/>}
                    {questModal.open && <QuestModal title={questModal.quest} close={() => closeQuest()}/>}
                    <div className={activeTab === 0 ? "tab-panel map" : "tab-panel hidden"} role="tabpanel" id="mapTabPanel">
                        <div className="map-container">
                            {modal.open && <CareerModal ratings={ratings} addRating={addRating} close={() => setModal({...modal, open:false})} data={modal.type} />}
                            <Map openModal={(type) => setModal({open:true,type:type})} ratings={ratings}/>
                            <div className="toolbar">
                                <button className="button medium icon-btn" aria-label="zoom out" id="zoomOut">
                                    <Icon icon="zoom-out" size="24"/>
                                </button>
                                <button className="button medium icon-btn" aria-label="zoom in" id="zoomIn">
                                    <Icon icon="zoom-in" size="24"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={activeTab === 1 ? "tab-panel" : "tab-panel hidden"} role="tabpanel" id="notebookTabPanel">
                        <AboutMe openQuest={(info) => {setQuestModal(info);setAchievementsOpen(false);setModal({...modal, open:false})}} 
                            achievements={() => {setAchievementsOpen(true);setModal({...modal, open:false});setQuestModal({...questModal, open:false})}} 
                            changeAvatar={(job) => setActiveJob(job)}
                            avatar={activeJob}
                            onClick={tabClick}
                        />

                    </div>
                    {/* <div className={activeTab === 2 ? "tab-panel" : "tab-panel hidden"} role="tabpanel" id="recTabPanel">
                        <Progress />
                    </div> */}
                </div>
                {
                    chatOpen ? <div className="chatbot-container">
                        {/* <button className="button icon-btn close-btn"><Icon icon="close" size="18"/></button> */}
                            {chatOpen ? <ChatBot setModal={setModal} tabClick={tabClick}/> : <Chatbot/>}
                    </div> : <Chatbot />
                }
            </main>
        </div>
    )
}