import React from "react";

const QuestButton = (props) => {
    let quest = props.item

    return (
        <button className={props.highlight ? "quest-btn highlighted" :"quest-btn"} onClick={props.click}>
            {quest.toLowerCase() === 'manufacturing engineer' ? 
                <span className="image cluster-image CC13">
                    <img src={"./images/hotspot_manufacturing.png"} alt="manufacturing"/>
                </span>

                : <span className="image cluster-image CC4">
                    <img src={"./images/hotspot_business.png"} alt="business"/>
                </span>
            }
            
            <span>{quest}</span>
        </button>
    )
}
export default QuestButton