import React, { useState } from "react";

const ThumbnailVideo = (props) => {
    const [playing, setPlaying] = useState(false);

    let videoTag = props.type.toLowerCase().includes('engineer') ? "YNSzm9mqDJQ?si=WRJ18WTaA8MSaajp" : "Di2BSlaBn7o?si=YaAqbaRwjVx7O_FE"
    let videoThumb = props.type.toLowerCase().includes('engineer') ? "./images/video_thumbnail4.jpeg" : "./images/video_thumbnail2.png"
    return (
       <> {playing ? 
            <div className="image video video-container">
                <iframe className="fade-in" src={"https://www.youtube-nocookie.com/embed/" + videoTag + "&autoplay=1"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <img src={videoThumb} className="docked" alt="thumbnail"/>
            </div> 
            :
        <div className={"image video"}>
            <button className="play-icon" onClick={() => setPlaying(true)}><svg width="22" height="22" viewBox="0 0 66 72" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><g filter="url(#filter0_d_2120_12483)"><path d="M53.3136 39.891L17.8459 60.5101C14.836 62.2582 10.9731 60.1415 10.9731 56.6175V15.3793C10.9731 11.8609 14.8304 9.73868 17.8459 11.4923L53.3136 32.1114C53.9983 32.503 54.5675 33.0691 54.9634 33.7523C55.3592 34.4354 55.5677 35.2113 55.5677 36.0012C55.5677 36.7912 55.3592 37.567 54.9634 38.2502C54.5675 38.9333 53.9983 39.4994 53.3136 39.891Z" fill="white"></path></g><defs><filter id="filter0_d_2120_12483" x="0.973145" y="0.864868" width="64.5947" height="70.2703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2120_12483"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2120_12483" result="shape"></feBlend></filter></defs></svg></button>
            <img src={videoThumb} alt="thumbnail"/>
        </div>
        }
        </>
    )
}

export default ThumbnailVideo