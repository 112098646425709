import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {closeChat, setMessage, toggleCanType } from '../../redux/store';
import chatbot from '../../assets/images/bot.png';
import './chat.css';
import MessageArea from "./MessageArea";
import handleSend from "../../util/handleSend";
import {clearHistory} from "../../redux/allChat";

function ChatBot({ setModal, tabClick }) {
    const dispatch = useDispatch();
    const canType = useSelector(state => state.chat.canType);
    const imageRef = useRef(null);
    const [isImageVisible, setImageVisible] = useState(true); // State to manage the visibility
    const mapData = useSelector(state => state.chat.mapData);
    const chatHistory = useSelector(state => state.history);


    useEffect(() => {
        console.log('23', mapData)
    },[mapData])

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    setImageVisible(false);
                    console.log('The chatbot image has been scrolled out of view.');
                    // Perform any action when the image goes out of view
                } else {
                    setImageVisible(true);
                    console.log('The chatbot image is in view.');
                    // Perform any action when the image comes into view
                }
            });
        }, {
            root: document.querySelector('.chat-area'), // using the chat-area as the viewport
            rootMargin: '0px',
            threshold: 0.1 // Adjust if you need partial visibility to trigger
        });

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if(imageRef.current) {
                observer.disconnect();
            }
        };
    }, []);


    return (
        <div className="chat-area" style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            borderBottom: '1px solid black',
            height: '100%',
            overflow: 'scroll',
            paddingBottom: '100px'
        }}>
            <div className="sidebar-header" style={{borderBottom: '1px solid #ffffff40'}}>
                <div className={'fade-in'}>
                    <img src={chatbot} className="bot-image"/>
                    <h2 className="mobile-h3 bold">Career coach </h2>
                </div>
                <div style={{
                    display: 'flex',
                    justifySelf: "flex-end",
                    width: "100%",
                    justifyContent: "flex-end",  // Ensure this is what you intended, it was 'end' before which is not a valid value.
                    paddingRight: "8px",
                    marginTop: "0"
                }}
                >
                    {chatHistory.length > 5 ? <button onClick={()=> {dispatch(clearHistory())}} style={{paddingRight:'10px', background: "transparent", color: 'white', border: 0, boxShadow: 'none'}}>New conversation
                    </button> : null }
                    <button onClick={() => {
                        dispatch(closeChat())
                    }} className="close-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858Z" fill="#FEFEFE"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="info-container">

            </div>
            {canType ? <MessageArea tabClick={tabClick}/> : <div style={{height: '72px'}}></div>}
        </div>
    );
}

export default ChatBot;
