import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleCareer, resetCareer } from "../../../redux/singleCareer";

const CareerModal = (props) => {
    const [rating, setRating] = useState('');
    const dispatch = useDispatch()
    const career = useSelector((state) => state.career)

    useEffect(() => {
        if (props.ratings) {
            let thisCluster = props.ratings.filter(f => f.cluster === props.data.id)[0]
            setRating(thisCluster?.rating)
        }
    },[props.ratings])

    useEffect(() => {
       if(!career.name || career.cluster_id !== props.data.id){
         dispatch(fetchSingleCareer(props.data.id))
       }

       return () => {
        resetCareer()
       }
    },[dispatch])

    return (
        <div className={props.type === 'cover' ? "modal-overlay" : "modal-overlay"}>
            <div className="modal career-modal">
                <button className="button icon-btn close-btn" aria-label="close" onClick={() => props.close()}>
                    <Icon icon="close" size="24"/>
                </button>
                <div className="modal-body">
                    <div className="flex-row">
                        <div className="left-col">
                            <div className={`cluster-image image ${career.cluster_id}`}>
                                {career.thumbnail && <img src={"./images/"+career.thumbnail} alt=""/>}
                            </div>
                            
                        </div>
                        <div className="right-col">
                            <h1 className="desktop-h4 bold">{career.name ? career.name : "Career cluster title"}</h1>
                            <p className="body-text small">{career.description ? career.description : "loading description..."}</p>
                            <h2 className="desktop-h6 bold">This might be for you if you:</h2>
                            <ul>
                                <li><p className="body-text caption">Might enjoy taking a leadership role in projects and activities</p></li>
                                <li><p className="body-text caption">Can communicate ideas and detailed information</p></li>
                                <li><p className="body-text caption">Take a logical approach to planning and solving problems</p></li>
                            </ul>
                            <div className="benefits benefits-row">
                                <div className="benefit-item">
                                    <h2 className="body-text bold">
                                        <span className="icon"><Icon icon="trending-up" size="16"/></span> 
                                        Growth potential</h2>
                                    <p className="body-text small">3% within the next 10 years</p>
                                </div>
                                <div className="benefit-item">
                                    <h2 className="body-text bold">
                                        <span className="icon"><Icon icon="degree" size="16"/></span>
                                            Degree needed</h2>
                                    <p className="body-text small">Associate or Bachelor's</p>
                                    </div>
                            </div>
                            <div className="rating-row action-row">
                                <button className="button light outline medium rating-btn" onClick={() => props.addRating(career.cluster_id,'notinterested')} aria-checked={rating === 'notinterested'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M15.6663 4.99743C15.6663 2.68275 14.2186 0.743427 12.2961 0.743427H10.9262C12.3273 1.76782 12.95 3.31616 12.9188 5.07563C12.8955 7.03062 12.1404 8.43034 11.4633 9.27487H12.5841C14.3198 9.27487 15.6663 7.3981 15.6663 4.99743ZM11.8758 5.05217C11.9225 2.19792 9.61866 0.039637 5.99935 0.00835754L4.94858 0.000537664C3.93674 -0.00728219 3.18953 0.0709161 2.76922 0.188215C2.16212 0.336792 1.57836 0.704323 1.57836 1.44721C1.57836 1.74437 1.65619 1.97114 1.74959 2.13536C1.81186 2.2292 1.80407 2.31522 1.70289 2.35432C1.2281 2.54981 0.823364 3.01118 0.823364 3.62114C0.823364 3.98085 0.924549 4.28582 1.10357 4.50478C1.18918 4.62208 1.17362 4.72373 1.03352 4.80976C0.683262 5.01307 0.434193 5.45098 0.434193 5.95927C0.434193 6.3268 0.550944 6.70999 0.753313 6.89763C0.885631 7.00713 0.862281 7.08534 0.729963 7.20265C0.488676 7.40597 0.333008 7.75784 0.333008 8.20359C0.333008 8.9543 0.916765 9.57208 1.68732 9.57208H4.42709C5.11982 9.57208 5.57904 9.93175 5.57904 10.5104C5.57904 11.5505 4.28699 13.4663 4.28699 14.8426C4.28699 15.5777 4.74621 16 5.3611 16C5.91373 16 6.18615 15.6168 6.48192 15.0303C7.64165 12.7704 9.18276 10.9405 10.3581 9.36876C11.3544 8.03937 11.8447 6.89763 11.8758 5.05217Z" fill="currentColor"/>
                                </svg>
                                <span> Not for me</span>
                                </button>
                                
                                <button className="button light outline medium rating-btn" onClick={() => props.addRating(career.cluster_id,'interested')} aria-checked={rating === 'interested'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M0.333008 11.0029C0.333008 13.3177 1.77221 15.2571 3.70151 15.2571H5.0707C3.67039 14.2326 3.09472 12.6842 3.09472 10.9247C3.1025 8.96968 3.8571 7.56991 4.52614 6.72529H3.41367C1.67885 6.72529 0.333008 8.60212 0.333008 11.0029ZM4.16828 10.9403C4.16828 13.7947 6.38542 16 10.0029 16H11.0609C12.0644 16 12.8113 15.9296 13.2314 15.8123C13.8382 15.6637 14.4216 15.2884 14.4216 14.5533C14.4216 14.2483 14.3438 14.0293 14.2504 13.8651C14.1882 13.7634 14.2038 13.6852 14.2972 13.6461C14.7794 13.4428 15.1762 12.9892 15.1762 12.3715C15.1762 12.0195 15.0751 11.7067 14.9039 11.4956C14.8183 11.3783 14.8261 11.2688 14.974 11.1906C15.3162 10.9872 15.5652 10.5494 15.5652 10.0411C15.5652 9.6735 15.4485 9.29032 15.2462 9.10261C15.1217 8.99317 15.1451 8.91496 15.2696 8.79764C15.5107 8.59431 15.6663 8.24244 15.6663 7.79667C15.6663 7.04594 15.0829 6.42814 14.3127 6.42814H11.5743C10.8819 6.42814 10.4229 6.06842 10.4229 5.48973C10.4229 4.44965 11.7221 2.5259 11.7221 1.14956C11.7221 0.422286 11.2553 0 10.6486 0C10.0884 0 9.81619 0.383186 9.52051 0.969692C8.36144 3.22971 6.82107 5.05962 5.64638 6.62364C4.65061 7.95309 4.16828 9.10261 4.16828 10.9403Z" fill="currentColor" />
                                    </svg>

                                    <span>I'm interested</span>
                                </button>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default CareerModal