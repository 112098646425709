import handleSend from "./handleSend";
import { fetchAiBlurb } from "./fetchAiBlurb";
import {fetchGrowingJobsAi} from "./fetchGrowingJobsAi";

export async function prepareMapNudgeData(dispatch) {
    // Fetch the AI-generated blurb using a separate function for cleanliness.
    // const jobClusters = await fetchGrowingJobsAi(data.title);
    // Build the new data object with enhanced clarity and structure.
    let newData = {
        title:"Pathway",
        message: `I hope you’re ready to keep exploring, because we’re just getting started!
        Now that you’ve added some of your interests to your profile, I’ve got more recommendations for you.
        Check out the map to see the new career clusters I’ve highlighted for you. 
        You can get there by selecting the map icon in the lower left by your avatar, or select the button below.`,
        hasButtons: false, // Simplified as the buttons array is statically populated above.,
        video: false,
        about: false,
        mapButton: true
    };

    // Send the new data using the provided dispatch function.
    await handleSend(dispatch, newData, 'system');
}

