export async function sendAddJobsRequest(userMessage, number) {

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat/jobs/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userMessage + ". I have added " + (number + 1) + " jobs." }),
    });
    console.log(response)
    // console.log("this is your " + number + " job")
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json()
}
