import React, { useEffect, useState } from "react";
import HSAvatar from "../assets/images/avatar-highschool.png";
import WHAvatar from "../assets/images/avatar-logistics.png";
import MFAvatar from "../assets/images/avatar-manufacturing.png";
import { useSelector, useDispatch } from "react-redux";
import { addJob } from "../util/addJob";
import {fetchGrowingJobsAi} from "../util/fetchGrowingJobsAi";
import {prepareModalData} from "../util/prepareModalData";
import { updateRatings } from "../redux/user";
import {setLoading} from "../redux/store";
import {clearHistory} from "../redux/store";
import {prepareTabModalData} from "../util/prepareTabModalData";
import { fetchCareers } from "../redux/allCareers";
import { Link } from "react-router-dom";
import CareerModal from "./shared/modals/CareerModal";
const AboutMe = (props) => {
    const [modal, setModal] = useState({open:false, type:{}});
    const [ratings, setRatings] = useState([])

    const user = useSelector(state => state.user);
    const clusters = useSelector(state => state.careers.data);
    const dispatch = useDispatch();
    const canType = useSelector(state => state.chat.canType);
    const careers = useSelector(state => state.careers.data);

    useEffect(() => {
        dispatch(fetchCareers());
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            setRatings(user.ratings)
        }
    },[user])

    async function handleClick (job) {
        // props.openQuest({open: true, quest: job})
        if (props.avatar === job) {
            //turn off
            props.changeAvatar('')
        } else {
            props.changeAvatar(job)
            dispatch(clearHistory())
            dispatch(setLoading(true))
            let data ={}
            data.title = job
            await prepareTabModalData(dispatch, data) // prepares the data to send to our chat bot
        }
        
    }

    const getCareerCluster = (id) => {
        // console.log(user)
        // console.log(clusters)
        // console.log(id, clusters.filter(f => f.cluster_id === id)[0])
        return clusters.filter(f => f.cluster_id === id)[0]
    }

    const openModal = (cluster) => {
        let modalInfo = {};
            modalInfo.id = cluster.cluster_id
            modalInfo.title = cluster.name
            modalInfo.blurb = cluster.description

        setModal({open:true, type:modalInfo})
        dispatch(setLoading(true))
        prepareModalData(dispatch, modalInfo, canType, careers,) // prepares the data to send to our chat bot
    }
    const addRating = (id, rating) => {
        console.log("rating here")
        let existingRatings = [...ratings],
            ratingToUpdate = existingRatings.findIndex(f => f.cluster === id);
        
        let updatedRating = {
            ...existingRatings[ratingToUpdate],
            rating: rating
        }
        existingRatings[ratingToUpdate] = updatedRating

        dispatch(updateRatings(existingRatings))

    }

    return (
        <>
            {
                user.firstname ?
                <div className={props.avatar.toLowerCase().includes('logistics') ? "about-me-container warehouse" : props.avatar.toLowerCase().includes('engineer') ? "about-me-container manufacturing" : "about-me-container"}>
                    {modal.open && <CareerModal ratings={ratings} addRating={addRating} close={() => setModal({...modal, open:false})} data={modal.type} />}
                <div className="card about-me">
                    <div className="card-header">
                        <h1 className="mobile-h3 bold">My profile</h1>
                    </div>
                    <div className="card-body">
                        <div className="group">
                            <h2 className="desktop-h5 bold">Career Clusters</h2>
                            <div className="chip-list cluster-list">
                                {/* Map careers */}
                                {
                                    user.ratings.filter(f => f.rating === 'interested')?.map(item => {
                                    let cluster = getCareerCluster(item.cluster)
                                        return (
                                        <button className={"career-cluster"} title={cluster.name} onClick={() => openModal(cluster)}>
                                            <div className={`image ${cluster.cluster_id}`}>
                                                <img src={"./images/"+cluster.thumbnail} alt=""/>
                                            </div>
                                            <p className="card-title">{cluster.slug ? cluster.slug : cluster.name}</p>
                                        </button>)
                                    })
                                }
                                 <button className={"career-cluster"} onClick={() => props.onClick({item: 0, url: 'map'})}>
                                            <div className={`image blank`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none">
                                                <path d="M11.3232 24.6533L18.3105 28.5498C18.6621 28.7402 18.9551 28.8867 19.3213 28.9746V4.64355L12.5244 0.541992C12.085 0.27832 11.6748 0.0878906 11.3232 0V24.6533ZM0 26.6162C0 27.8906 0.805664 28.5938 1.97754 28.5938C2.40234 28.5938 2.8418 28.4619 3.33984 28.1982L9.16992 25.0342V0.161133C8.84766 0.292969 8.4668 0.454102 8.07129 0.688477L1.4502 4.45312C0.454102 5.00977 0 5.80078 0 6.91406V26.6162ZM21.46 28.9014C21.6357 28.8574 21.8115 28.7842 21.9727 28.667L29.3848 24.4775C30.4102 23.9062 30.8789 23.1299 30.8789 22.0166V2.41699C30.8789 1.08398 30.0586 0.424805 28.8135 0.424805C28.3594 0.424805 27.876 0.556641 27.3633 0.834961L21.46 4.11621V28.9014Z" fill="white" fill-opacity="0.8"/>
                                            </svg>
                                            </div>
                                            <p className="card-title">Explore more</p>
                                        </button>
                            </div>
                        </div>
                        <div className="group">
                            <h2 className="desktop-h5 bold">Jobs / Specialties</h2>
                            <div className="chip-list">
                                {/* Map jobs, once selected in chat */}
                                {user.jobs.length > 0 ? <>
                                    {user.jobs.map(job => (
                                        <button className={props.avatar === job ? "chip-label dark selected" : "chip-label dark"} onClick={() => handleClick(job)}>{job}</button>
                                    ))}
                                    </> 
                                    : <p className="text-body small" style={{margin:0}}>No jobs selected</p>
                                }
                            </div>
                        </div>
                        <div className="group">
                            <h2 className="desktop-h5 bold">Interests</h2>
                            <div className="chip-list">
                                {/* Map interests */}
                                {
                                    user.extracurriculars.map(interest => (
                                        <div className="chip-label dark">{interest}</div>
                                    ))
                                }
                                <div className="chip-label dark">+ Add more</div>
                            </div>
                        </div>
                        <div className="group">
                            <div className="group-header flex-row">
                                <h2 className="mobile-h3 bold">Achievements</h2>
                                <button className="button xsmall outline light" onClick={() => props.achievements()}>View all</button>
                            </div>
                            
                            <h3 className="desktop-h5 bold">Skills</h3>
                            <ul className="skills-list">
                                {/* Map skills */}
                                {
                                    user.skills.map(skill => (
                                        <li className="skills-item" key={"skill-"+skill.name?.replace(' ',"")}>
                                            <span className="icon" dangerouslySetInnerHTML={{__html: skill.icon }}></span>
                                            <span className="label">{skill.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="group">
                            <h3 className="desktop-h5 bold">Badges</h3>
                            <div className="badges-group card-row overflow-scroll">
                            <div className="card achievement-card">
                            <div className="image">
                                <img src={"./images/badge-trophy.png"} alt="logo"/>
                                </div>
                                <div className="card-info">
                                    <p className="card-title">Get onboard</p>
                                    <p>Completed: today</p>
                                </div>
                                
                            </div>
                            {user.jobs.length > 0 ? <>
                                    {user.jobs.map(job => (
                                        <div className="card achievement-card locked">
                                
                                        <div className="image">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24" fill="none">
                                            <path d="M15.2089 6.83791V9.80423C16.6113 10.008 17.3496 10.9604 17.3496 12.6605V21.0886C17.3496 23.0214 16.3954 24 14.5973 24H3.40464C1.60647 24 0.652344 23.0214 0.652344 21.0886V12.6605C0.652344 10.9604 1.39065 10.008 2.79301 9.80423V6.83791C2.79301 2.23853 5.80218 0 8.99485 0C12.1997 0 15.2089 2.23853 15.2089 6.83791ZM8.12635 13.2844L8.24867 17.4312C8.2609 17.9082 8.53002 18.1896 8.99485 18.1896C9.45968 18.1896 9.72879 17.9082 9.74102 17.4312L9.86335 13.2966C9.87558 12.8195 9.49638 12.4526 8.99485 12.4526C8.48108 12.4526 8.11412 12.8073 8.12635 13.2844ZM4.73797 6.5688V9.76146H13.2761L13.2762 6.5688C13.2762 3.48623 11.2823 1.84709 8.99485 1.84709C6.70738 1.84709 4.73797 3.48623 4.73797 6.5688Z" fill="white" fill-opacity="0.4"/>
                                            </svg>
                                        </div>
                                        <div className="card-info">
                                            <p className="card-title">Pending</p>
                                            <p>Complete pathway</p>
                                        </div>
                                    </div>
                                    ))}
                                    </> 
                                    : <></>
                                }
                            
                        </div>
                        </div>
                        <div className="group">
                            <h3 className="desktop-h5 bold">Assessments</h3>
                            <p className="group-label">1 of 3 completed</p>
                            <div className="assessment-group">
                                <div className="card dark assessment-card">
                                    <div className="image">
                                    <img src={"./images/assessment-bubble-1.png"} alt="logo"/>
                                    </div>
                                    <p className="card-title">Which career for me?</p>
                                </div>
                                <div className="card dark assessment-card locked">
                                    <div className="image">
                                    <img src={"./images/assessment-bubble-2.png"} alt="logo"/>
                                    </div>
                                    <p className="card-title">Watson-Glaser™ III</p>
                                </div>
                                <div className="card dark assessment-card locked">
                                    <div className="image">
                                    <img src={"./images/assessment-bubble-3.png"} alt="logo"/>
                                    </div>
                                    <p className="card-title">Work Style Lens (WS-Lens)</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="avatar">
                    <button>Edit Photo</button>
                    <img src={props.avatar.toLowerCase().includes('logistics') ? WHAvatar : props.avatar.toLowerCase().includes('engineer') ? MFAvatar : HSAvatar} alt="Jason Matthews"/>
                </div>
            </div> :
            <div>Loading...</div>
        }
        </>
    )
}
export default AboutMe